<div class="font-weight-bold" *ngFor="let mode of modeArray">
  <ng-container *ngIf="mode === 'product'">
    <ul>
      <li *ngFor="let item of indemnityCoverage">{{ item.planName }}</li>
    </ul>
  </ng-container>
  <ng-container *ngIf="mode === 'rider'">
    <ul>
      <li *ngFor="let item of indemnityCoverage">
        {{ item.planName }} - {{ item.riderDescription }}
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="mode === 'distinctRider'">
    <ul>
      <li *ngFor="let item of distinctIndemnityCoverage">
        {{ item.planName }} - {{ item.riderDescription }}
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="mode === 'otherCoverage'">
    <ul>
      <li *ngFor="let coverage of otherCoverage">
        {{ coverage.otherPlanName }}
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="mode === 'policyInformation'">
    <ul>
      <li *ngFor="let policy of policyInformation">
        {{ policy.product }}
        <span *ngIf="policy.applicantType">({{ policy.applicantType }})</span>
      </li>
    </ul>
  </ng-container>
</div>
