import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

declare module 'dayjs' {
  interface Dayjs {
    fromNow();
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {}

  public clarityInjection(env: string) {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', env);
  }

  public setIdentity(name: string) {
    this.clarity('identify', name);
  }

  private clarity(arg1: string, arg2: string, arg3?: string) {
    (window as any).clarity(arg1, arg2, arg3);
  }
}