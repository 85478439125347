import { Component, OnInit, OnDestroy } from '@angular/core';
import { ScriptService } from 'src/app/shared/services/script/script.service';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Question } from 'src/app/shared/models/vcall/question';
import { Subscription } from 'rxjs';
import {
  IndemnityCoverage,
  PolicyInformation,
  Product,
} from 'src/app/shared/models/application/product';
import { OtherCoverage } from 'src/app/shared/models/application/application';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit, OnDestroy {
  question: Question;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  indemnityCoverage: Array<IndemnityCoverage>;
  distinctIndemnityCoverage: Array<IndemnityCoverage>;
  product: Product;
  otherCoverage: OtherCoverage[];
  policyInformation: Array<PolicyInformation> = [];
  filteredPolicyInformation: Array<string> = [];
  modeArray: Array<string>;
  exclusions: Array<string>;
  constructor(
    private scriptService: ScriptService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.scriptService.SelectedQuestion.subscribe((q) => {
        this.question = q;
        if (this.question && this.question.details.mode) {
          this.modeArray = this.question.details.mode.split(',');
        }
        if (this.question && this.question.details.exclusions) {
          this.exclusions = this.question.details.exclusions.split(',');
        }
      })
    );
    this.subscriptions.push(
      this.applicationService.SelectedApplication.subscribe((a: any) => {
        this.indemnityCoverage = a.indemnityCoverage;
        this.distinctIndemnityCoverage = a.indemnityCoverage;
        this.otherCoverage = a.otherCoverage;
        this.policyInformation = a.policyInformation.sort((m, n) => {
          return m.policyNumber === n.policyNumber
            ? 0
            : +(m.policyNumber > n.policyNumber) || -1;
        });
        
        const productMap = new Map();
        this.policyInformation.forEach((policy) => {
          if (!productMap.has(policy.product)) {
            productMap.set(policy.product, []);
          }
          productMap.get(policy.product).push(policy);
        });
 
        productMap.forEach((policies) => {
          if (policies.length > 1) {
            policies.forEach((policy) => {
              if (policy.policyNumber.endsWith('U')) {
                policy.applicantType = 'Primary Applicant';
              } else {
                policy.applicantType = 'Co-applicant';
              }
            });
          }
        });
 
        this.policyInformation = Array.from(productMap.values()).flat();
        if (
          this.distinctIndemnityCoverage &&
          this.distinctIndemnityCoverage.length > 0
        ) {
          this.distinctIndemnityCoverage =this.distinctIndemnityCoverage.filter(
              (v, i, ele) =>
                ele.findIndex(
                  (t) =>
                    t.planName === v.planName &&
                    t.riderDescription === v.riderDescription
                ) === i
            );
        }
        if (this.indemnityCoverage && this.indemnityCoverage.length > 0) {
          this.indemnityCoverage = this.indemnityCoverage.filter(
            (v, i, ele) => ele.findIndex((t) => t.planName === v.planName) === i
          );
        }

        if (this.exclusions && this.exclusions.length > 0) {
          this.modeArray.forEach((m) => {
            this.filterExclusion(m);
          });
        }
      })
    );
  }

  private filterExclusion(modeText) {
    switch (modeText) {
      case 'policyInformation':
        this.exclusions.forEach((e) => {
          this.policyInformation = this.policyInformation.filter((p) => {
            return p.product !== e;
          });
        });
        break;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((a) => a.unsubscribe());
  }
}
